<!--HRM人力资源管理系统-->
<template>
  <div class="hrm-container">
    <new-cloude-header/>
    <headerBanner :content="bannerContent" title="HRM人力资源管理系统" @btnClick="bannerClick"
                  imgStyle="width:359px;height:367px" :imgSrc="`${require('@/assets/img/solution/HRM人力资源管理系统.png')}`"
                  contentStyle="width:90%" :bodyStyle="bodyStyle"/>

    <!--人力资源发展面临的难题-->
    <div class="module-1">
      <div class="module-content">
        <p class="module-title">人力资源发展面临的难题</p>
        <div class="module-col">
          <el-row :gutter="15">
            <template v-for="list of dataList">
              <el-col :span="6">
                <div class="col-content">
                  <p class="default-title">{{ list.title }}</p>
                  <ul>
                    <template v-for="ul of list.introduce">
                      <li class="list-style-type">{{ ul }}</li>
                    </template>
                  </ul>
                </div>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
    </div>
    <!--HRM系统核心亮点-->
    <div class="module-2">
      <div class="module-content">
        <p class="module-title">HRM系统核心亮点</p>
        <div class="module-tabs">
          <template v-for="list of datalist2">
            <div :class="activeName==list.value ? 'active tabs-list' : 'tabs-list'" @click="activeName=list.value">
              {{ list.name }}
            </div>
          </template>
        </div>
        <div class="tabs-content">
          <template v-for="data of datalist2">
            <template v-if="data.value==activeName">
              <div class="tabs-col">
                <div class="tabs-left" :style="data.image"/>
                <div class="tabs-right">
                  <p class="tabs-title">{{ data.name }}</p>
                  <div class="tabs-ul">
                    <ul>
                      <template v-for="ul of data.list">
                        <li class="list-style-type">{{ ul }}</li>
                      </template>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
    <!--自动化报表和决策支持-->
    <div class="module-3">
      <div class="module-content">
        <p class="module-title" style="margin-block-end: 20px;">HRM选·用·育·留的人事管理方案</p>
        <div class="module-img"/>
      </div>
    </div>
    <!--帮企数智云HRM的产品价值-->
    <div class="module-4">
      <div class="module-content">
        <p class="module-title">帮企数智云HRM的产品价值</p>
        <div class="module-col">
          <el-row :gutter="15">
            <template v-for="list of datalist3">
              <el-col :span="6">
                <div class="tabs-col">
                  <p class="tabs-title">{{ list.title }}</p>
                  <div class="tabs-ul">
                    <ul>
                      <template v-for="ul of list.introduce">
                        <li class="list-style-type">{{ ul }}</li>
                      </template>
                    </ul>
                  </div>
                </div>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
    </div>
    <applyTrial ref="dialog" :servicePlatform="'HRM人力资源管理系统'"/>
    <new-cloude-footer/>
  </div>
</template>

<script>
import NewCloudeFooter from "@/views/home/newCloudeFooter";
import NewCloudeHeader from "@/views/home/newCloudeHeader";
import headerBanner from "@/components/assembly/headerBanner";
import applyTrial from "@/components/assembly/applyTrial";

export default {
  name: "hrm",
  components: {NewCloudeFooter, NewCloudeHeader, headerBanner,applyTrial},
  data() {
    return {
      bannerContent: '从本质上释放HR的双手，提高工作效能，提升员工使用体验，深入数据洞察，助力企业科学智慧决策，开放的Paas平台，满足企业集团化、个性化管理需求。',
      bodyStyle: `background-image:url(${require('@/assets/img/solution/beijjing.png')});background-size: 400px;background-repeat: no-repeat;background-position: right 25px;`,
      activeName: '1',
      dataList: [
        {title: '如何招募人才', introduce: ['如何一键发布招聘需求', '如何统一回收各渠道简历', '如何开展在线面试', '如何让同事发挥招聘作用']},
        {title: '如何培养人才', introduce: ['如何引导入职培训', '如何建立自助学习平台', '如何便捷地找人找事', '如何在工作中推送知识']},
        {title: '如何发现人才', introduce: ['如何知晓新人工作情况', '如何全方面了解员工', '如何评估员工月度绩效', '如何让员工围绕目标做事']},
        {title: '如何留住人才', introduce: ['如何知晓员工勤奋度', '如何了解业绩与考核情况', '如何建立员工的自驱力', '如何知晓员工异动风险']},
      ],
      datalist2: [
        {
          name: '组织人事',
          value: '1',
          image: `background-image:url(${require('@/assets/img/solution/组织人事.png')})`,
          list: [
            '灵活支撑直线制、职能制、矩阵制等多种组织结构，拖拽式批量调整，智能联动汇报关系，权限，薪酬信息等',
            '员工全景档案：归集简历，面试，测评，薪资，假勤，绩效等全生命周期信息，支持企业人才画像建模和全景数据分析',
            '无缝衔接招聘：联动offer-入职-转正-调动等流程，智能事件提醒与流程管控',
            '“极速入职”：入职日扫码报道，自助办理入职，合规高效签署电子合同'
          ]
        },
        {
          name: '薪酬管理',
          value: '2',
          image: `background-image:url(${require('@/assets/img/solution/薪酬管理.png')})`,
          list: [
            '支持落地多套薪资体系，灵活自定义算薪规则；自动完成薪酬数据差异分析，准确高效完成薪酬核算',
            '实时同步300+城市社保规则，合规校验',
            '无缝连接报税系统，多法人实体一键算薪算税，轻松完成在线申报与缴款',
            '自动集成发薪等数据，进行多维度的人工成本分摊，实现人力成本精细化管理'
          ]
        },
        {
          name: '考勤管理',
          value: '3',
          image: `background-image:url(${require('@/assets/img/solution/考勤管理.png')})`,
          list: [
            '支持综合工时、弹性打卡、坐班、排班等多种考勤制度，并根据业务线或部门设置多种考勤及加班补偿规则',
            '支持弹性班次、一天多班次、超长班次等特殊班次管理，划线排班、循环排班、按组排班等排班方式',
            '自动汇总日周月报，联动核算薪资，更能智能穿透问题数据，快速完成考勤核对，提升HR管理效率'
          ]
        },
        {
          name: '绩效管理',
          image: `background-image:url(${require('@/assets/img/solution/绩效管理.png')})`,
          value: '4',
          list: [
            '包含目标管理，沟通反馈，绩效评估，绩效分析等绩效全流程管理',
            '支持 KPI、MBO、360、PBC、OKR、双轨制等多种绩效管理体系',
            '帮助HR掌控绩效推进进程，分析关键截点，发现问题并有效改进',
            '支持对比分析、绩效排名、历年趋势分析、绩效过程数据分析等，结合AI与机器学习技术，提升企业管理决策效率与决策能力'
          ]
        },
        {
          name: '培训学习',
          value: '5',
          image: `background-image:url(${require('@/assets/img/solution/培训学习.png')})`,
          list: [
            '包含培训项目管理，课程管理，在线考核，进度查询，激励管理，移动端学习等培训全场景所需',
            '完整的积分激励体系，协作与闯关式学习，提高员工的学习体验',
            '无缝衔接人才测评、内部人才库平台，支持人才能力诊断、培训效果追踪，打通培训前后端',
            '归集测评、绩效等人才数据信息，结合AI与机器学习技术，快速分析，优化企业培训运营'
          ]
        }
      ],
      datalist3:[
        {title: '提升组织效能', introduce: [
            '落地复杂的假勤薪酬体系，实现智能核算假勤、薪资、批量报税等，减少时间和人力成本，提高效率',
            '应对频繁的人员异动和组织调整，通过强大的规则配置，联动考勤、绩效、薪资调整'
          ]
        },
        {title: '提高员工体验', introduce: [
            '扫码入职引导、电子合同、电子工资条、在线证明申请等让员工办理业务更便捷，流程进度更清晰',
            '移动端全场景自助，7*24智能员工服务机器人、勋章、打赏、祝福等社交化应用，打造温暖组织'
          ]
        },
        {title: '驱动智慧决策', introduce: [
            '大数据Ocean数据分析平台，归集假勤、薪资、绩效多维度信息，生成企业人才画像，助力人才管理',
            '快速定制分析报表，指定共享人员，实时更新数据，支持行业数据对标，实现洞察业务'
          ]
        },
        {title: '满足个性化需求', introduce: [
            '开放的企业级PaaS平台，真正实现【零代码】快速配置、【低代码】敏捷开发，满足个性化需求的同时，减少投入成本'
          ]
        }
      ]
    }
  },
  methods: {
    bannerClick() {
      this.$refs.dialog.show = true;
    }
  }
}
</script>

<style scoped lang="scss">
.hrm-container {
  background: #FFFFFF;

  .module-1 {
    background: #FFFFFF;

    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;

      .module-col {
        .col-content {
          background: #F7F8FF;
          padding: 30px 30px 20px;

          .default-title {
            font-size: 18px;
            font-family: 'SourceHanSansCN';
            color: #333333;
            padding-left: 10px;
            border-left: 4px solid #1935DE;
            margin-block-end: 15px;
            margin-block-start: 0px;
            line-height: 18px;
          }

          ul {
            margin-block-start: 15px;
            margin-block-end: 0px;
            margin-inline-start: 5px;
            margin-inline-end: 0px;
            padding-inline-start: 0px;
            font-size: 16px;
          }

          .list-style-type {
            list-style-type: "·";
            padding-left: 10px;
            color: #333333;
            font-family: "SourceHanSansCN";
            padding-bottom: 10px;
          }

          ::marker {
            color: #333333;
            unicode-bidi: isolate;
            font-variant-numeric: stacked-fractions;
            text-transform: none;
            text-indent: 0px !important;
            text-align: start !important;
            text-align-last: start !important;
            font-size: large;
          }
        }
      }
    }
  }

  .module-2 {
    background: #F7F8FE;

    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;

      .module-tabs {
        width: 1000px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 60px;
        border: 1px solid #DDDDDD;
        background: #F7F8FE;

        .tabs-list {
          color: #333333;
          font-family: 'SourceHanSansCN';
          font-size: 18px;
          padding-bottom: 15px;
          padding-top: 15px;
          width: 33.33333%;
          text-align: center;
          border-radius: 60px;
          cursor: pointer;
        }

        .active {
          background: #1935DE;
          color: #FFFFFF;
        }
      }

      .tabs-content {
        margin-top: 60px;

        .tabs-col {
          padding: 0;
          display: flex;
          justify-content: space-between;

          .tabs-left {
            width: 580px;
            height: 330px;
            background-size: 100% 100%;
          }

          .tabs-right {
            width: 570px;

            .tabs-title {
              font-size: 24px;
              color: #333333;
              font-family: 'SourceHanSansCN';
              margin-block-start: 0px;
              margin-block-end: 0px;
            }
          }


          ul {
            margin-block-start: 20px;
            margin-block-end: 0px;
            margin-inline-start: 15px;
            margin-inline-end: 0px;
            padding-inline-start: 0px;
            font-size: 16px;
          }

          .list-style-type {
            list-style-type: "◆";
            padding-left: 10px;
            color: #333333;
            font-family: "SourceHanSansCN";
            padding-bottom: 10px;
          }

          ::marker {
            color: #1935DE;
            unicode-bidi: isolate;
            font-variant-numeric: stacked-fractions;
            text-transform: none;
            text-indent: 0px !important;
            text-align: start !important;
            text-align-last: start !important;
            font-size: xx-small;
          }
        }
      }
    }
  }

  .module-3{
    height: 550px;
    background: #FFFFFF;
    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;
      .module-img{
        width: 1108px;
        height: 395px;
        background: url("~@/assets/img/solution/HRM选·用·育·留的人事管理方案.png");
        background-size: 100% 100%;
      }
    }
  }
  .module-4 {
    background: #F7F8FE;
    height: 467px;
    width: 100%;
    padding-bottom: 60px;
    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;
      .module-col {
        .tabs-col{
          background: #FFFFFF;
          padding: 30px 20px;
          height: 250px;
          .tabs-title {
            font-size: 18px;
            color: #333333;
            font-family: 'SourceHanSansCN';
            margin-block-start: 0px;
            margin-block-end: 0px;
          }
          ul {
            margin-block-start: 20px;
            margin-block-end: 0px;
            margin-inline-start: 15px;
            margin-inline-end: 0px;
            padding-inline-start: 0px;
            font-size: 16px;
          }
          .list-style-type {
            list-style-type: "◆";
            padding-left: 10px;
            color: #333333;
            font-family: "SourceHanSansCN";
            padding-bottom: 20px;
          }
          ::marker {
            color: #00FFF6;
            unicode-bidi: isolate;
            font-variant-numeric: stacked-fractions;
            text-transform: none;
            text-indent: 0px !important;
            text-align: start !important;
            text-align-last: start !important;
            font-size: xx-small;
          }
        }
      }
    }
  }
  .module-title {
    font-family: 'SourceHanSansCN';
    color: #333333;
    font-size: 30px;
    margin-block-start: 0px;
    margin-block-end: 40px;
    text-align: center;
  }
}
</style>
